import { React, Component, useState } from 'react'
import './../components/CameraPage.css';
import backImage from './../images/background.jpg';
import department from './../images/map_page/department.png';
import camera from './../images/camera_page/camera.png';
import ReactPlayer from 'react-player';
/*url='http://localhost:4000/index.m3u8'*/
/*<ReactPlayer url='http://localhost:4000/index.m3u8' playing={true}/>*/
export class CameraPage extends Component {
    
    render() {
        
        return (
            <div class="container_camera_page"
                style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backImage})`
                }}>
                    <div class="camera_title_block">
                            <div style={{ width: "auto", margin:"0 auto" }}>
                                <p class="camera_title">Запись</p>
                                <p class="camera_second_title">с камеры робота</p>
                            </div>
                    </div>
                <div class="camera_content_block">
                    <img src={camera} style={{
                        width: "40px"
                    }} />
                    <div style={{ display: "flex", justifyContent: "center", border: " 2px solid rgb(245,102,0)" }}>
                        <iframe src="https://rtsp.me/embed/TTHe683K/" style={{ border: "none", width: "100%", aspectRatio:"16 / 9" }} allowfullscreen></iframe>
                    </div>
                  
                    </div>
            </div>
        )
    }
}